.projects {
  background-color: #000000;
  padding-top: 50px;
  padding-bottom: 20vw;
  overflow:scroll;
  position: sticky;
}

.ProjectsHead {
  color: #ffff;
  text-align: center;
  font-size: 40px;
}

.ProjectsHead span {
  color: #fcc8b2;
}
.project-cards{
  display: flex;
  flex-direction: column-reverse;
  padding-left: 225px;
  align-items: center;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
}

.project-box {
  min-width: 400px; /* Adjust as needed based on your layout */
  display: none;
  justify-content: center;
  position: relative;
}

.video-container,
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideLeft 1s ease;
  width: 100%;
}
.full-video-container{
  width: 75%;
  height: auto;
}

.video-container video {
  width: 75%;
  height: auto;
}
.full-image-container img{
  width: 75%;
  height: auto;
}
.image-container img {
  width: 75%;
  height: auto;
}

.project-Text {
  color: #ffff;
  width: 75%;
  box-sizing: border-box;
  animation: slideRight 1s ease;
  justify-content: center;
}

.project-Text h3 {
  font-size: 30px;
  margin-bottom: 10px;
}
.project-Text h3 span {
  color: #fcc8b2;
}

.project-Text p {
  font-size: 16px;
  margin-bottom: 10px;
}
.project-card {
  padding-bottom: 75px;
}

/* Media Queries for responsiveness */

/* For screens smaller than 1200px */
@media (max-width: 933px) {
  .project-cards {
    display: none;
  }
  .project-box {
    display: flex;
    flex-basis: 100%;
    background-color: #000000;
    
  }
  .project-Text {
    color: #ffff;
    width: 75%;
    box-sizing: border-box;
    animation: slideRight 1s ease;
    justify-content: center;
  }
}
@media (max-width: 1558px) {
  .one {
    margin-left: 0;
  }
}
