.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-brand {
  margin-right: 2em;
}

.navbar-burger {
  position: relative; /* Ensure burger icon remains in the flow */
  z-index: 2; /* Ensure burger is above menu */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.navbar-burger span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #fff;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: -100%; /* Initially hide the menu to the left */
  width: 200px; /* Adjust width as needed */
  height: 100%; /* Full height of the viewport */
  background-color: #1d1a1a;
  padding-top: 60px; /* Adjust based on navbar height */
  transition: left 0.3s ease; /* Add transition for smooth animation */
}

.navbar-menu.is-active {
  left: 0; /* Show the menu by setting left to 0 */
}

.navbar-start {
  margin-top: 10px;
}

.navbar-items {
  font-size: 25px;
  display: block;
  color: #fbfbfb;
  text-decoration: none;
  padding: 10px 15px;
}

.navbar-items:hover {
  font-size: 30px;
  transition: 0.5s;
  color: #a3bbad;
}
.versionTag {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: #aba9a9;
  opacity: 0.5;
  font-size: 15px;
  z-index: 1000;
}

  @keyframes slideRight {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes slideLeft {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes slideTop {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes slideBottom {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  