.contact {
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  background-color: #000000;
  height: 100vh; /* Fill the entire viewport height */
}

.contact-container {
  width: 90%; /* Adjust width as needed */
  max-width: 800px; /* Set a maximum width */
  padding: 20px;
}

.contact-head {
  text-align: center;
  font-size: 4vw; /* Adjust font size relative to viewport width */
  color: #ffff;
}

.contact-head h3 {
  margin-bottom: 2vw; /* Adjust margin relative to viewport width */
}

.contact-head span {
  color: #fcc8b2;
}

.contact-content {
  font-size: 2vw; /* Adjust font size relative to viewport width */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust grid columns */
  grid-gap: 20px; /* Adjust grid gap */
  margin-top: 5vw; /* Adjust margin relative to viewport width */
}

.contact-gmail,
.contact-linkedIn {
  animation: slideTop 1s ease;
}

.contact-gmail i,
.contact-linkedIn i {
  font-size: 7vw; /* Adjust icon size relative to viewport width */
  padding-bottom: 1vw; /* Adjust padding relative to viewport width */
  color: #a3bbad;
}

.contact-gmail h4,
.contact-linkedIn h4 {
  color: #ffff;
  display: flex;
  justify-content: center;
  font-size: 5vw; /* Adjust font size relative to viewport width */
}

.contact-linkedIn a {
  text-decoration: none;
  color: #a3bbad;
}

.contact-linkedIn a:hover {
  color: #fcc8b2;
}

@keyframes slideTop {
  0% {
    transform: translateY(10vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
