.about {
  background-color: #000000;
  position: sticky;
}

.about-img {
  padding: 10%;
  animation: slideRight 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-img img {
  max-width: 100%;
  height: auto;
}

.about-info {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  animation: slideTop 1s ease;
  color: #ffffff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.4;
  text-align: center;
}

.about-btn {
  margin-top: 15%;
  display: inline-block;
  padding: 12px 28px;
  background: #4b01e0;
  border-radius: 40px;
  font-size: 16px;
  color: #000000;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0;
  animation: slideTop 0.5s ease forwards;
  box-shadow: 0 0 5px #4304c2, 0 0 25px #4304c2;
  transition: 0.3s;
}

.about-btn:hover {
  box-shadow: 0 0 5px #45464a, 0 0 25px #45464a, 0 0 50px #45464a,
    0 0 100px #45464a, 0 0 200px #45464a;
  padding: 24px 56px;
}

.about-btn a {
  text-decoration: none;
  color: #ffffff;
}

.AboutHead h3 {
  animation: slideLeft 1s ease;
  text-align: center;
  font-size: 60px;
}

.AboutHead span {
  color: #4304c2;
}
.SchoolHead h3 {
  color: #ffffff;
  animation: slideLeft 1s ease;
  text-align: center;
  font-size: 60px;
}
.school-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #ffffff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.4;
  text-align: center;
}
.school-img {
  animation: slideRight 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}
.school-img img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 540px) {
  .about {
    /* Adjust alignment */
    display: flex;
    flex-flow: column;
    gap: 0;
  }

  .about-img {
    align-self: center; /* Adjust alignment */
    margin-bottom: 0%;
  }

  .about-info {
    display: flex;
    flex-direction: column;
    text-align: left; /* Adjust alignment */
  }
  .school-img {
    align-self: center; /* Adjust alignment */
    margin-bottom: 0%;
  }
  .school-info {
    display: flex;
    flex-direction: column;
    text-align: left; /* Adjust alignment */
  }
}

@media only screen and (max-width: 375px) {
  .about {
    /* Adjust alignment */
    display: flex;
    flex-flow: column;
    height: 0%;
    gap: 0;
  }

  .about-img {
    align-self: center; /* Adjust alignment */
    height: auto;
  }

  .about-info {
    display: flex;
    flex-direction: column;
    text-align: left; /* Adjust alignment */
  }
  .school-img {
    align-self: center; /* Adjust alignment */
    height: auto;
  }
  .school-info {
    display: flex;
    flex-direction: column;
    text-align: left; /* Adjust alignment */
  }
}
