/* Make component background fill the screen */
.skills {
  position:sticky;
  background-color: #000000;
  font-size: 60px;
  line-height: 1.4;
  padding: 5vw 0;
  min-height: 100vh; /* Make the component at least the height of the viewport */
}

/* Make boxes stack on smaller screens */
.skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
}

/* Adjust box size and margins */
.skill-box-one {
  flex-basis: calc(90% - 3vw); /* Adjust width for smaller screens */
  padding: 4vw;
  /*font-size: 1.5vw;*/
  font-weight: 400;
  border-radius: 4vw;
  transition: background 0.5s, transform 0.5s;
  box-shadow: 1px 1px 10px #fcc8b2, 1px 1px 20px #a3bbad;
  width: 90%; /* Adjust width for smaller screens */
  height: auto; /* Auto height */
  margin-bottom: 2vw;
  animation: slideTop 1s ease forwards;
}
.skill-box-two {
  flex-basis: calc(90% - 3vw); /* Adjust width for smaller screens */
  padding: 4vw;
  /*font-size: 1.5vw;*/
  font-weight: 400;
  border-radius: 2vw;
  transition: background 0.5s, transform 0.5s;
  box-shadow: 1px 1px 10px #fcc8b2, 1px 1px 20px #a3bbad;
  width: 90%; /* Adjust width for smaller screens */
  height: auto; /* Auto height */
  margin-bottom: 4vw;
  animation: slideTop 1s ease forwards;
}

/* Make heading font size responsive */
.SkillsHead h3 {
  color: #ffff;
  text-align: center;
  font-size: 60px;
}
.SkillsHead span {
  color: #4304c2;
}

/* Adjust font size for smaller screens */
.skill-list h2 {
  color: #ffff;
  font-size: 3vw;
}
.skill-list h3 {
  color: #ffff;
  font-size: 2vw;
}

.skill-list p {
  color: #ffff;
  font-size: 1.5vw;
  line-height: 1.4;
}

.skill-list i {
  color: #ffff;
  font-size: 5vw;
}

.skill-list a {
  font-size: 1.2vw;
}

@keyframes slideTop {
  0% {
    transform: translateY(10vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Media query for screens larger than 768px */
@media screen and (min-width: 769px) {
  .skill-box-one {
    flex-basis: calc(40% - 1.5vw); /* Adjust width for larger screens */
    width: 40%;
    height: 30vw;
    margin: 1.5vw;
  }
  .skill-box-two {
    flex-basis: calc(40% - 1.5vw); /* Adjust width for larger screens */
    width: 40%;
    height: 30vw;
    margin: 1.5vw;
  }
}
@media screen and (max-width: 768px) {
  .skill-box-one {
    flex-basis: calc(90% - 3vw); /* Adjust width for smaller screens */
    width: 90%;
    margin: 1.5vw;
  }
  .skill-box-two {
    flex-basis: calc(90% - 3vw); /* Adjust width for smaller screens */
    width: 90%;
    margin: 1.5vw;
  }
  .skill-list h2 {
    font-size: 20px;
  }
  .skill-list h3 {
    font-size: 15px;
  }
  .skil-list p {
    font-size: 12px;
  }
  .skill-list i {
    font-size: 35px;
  }
}
