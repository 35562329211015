.home {
  position: fixed;
  width: 100%;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 25px 10% 0 10%;
  background-color: #000000;
}
.Home-Content {
  max-width: 600px;
  padding-bottom: 100px;
}
.Home-Content h3 {
  font-size: 32px;
  font-weight: 700px;
  opacity: 0;
  animation: slideBottom 1s ease forwards;
  animation-delay: 0.7s;
  color: #ffff;
}
.Home-Content h1 span {
  color: #ffffff;
}
.Home-Content h1 {
  font-size: 56px;
  font-weight: 700;
  color: #fcc8b2;
  margin: -3px 0;
  opacity: 0;
  animation: slideRight 1s ease forwards;
  animation-delay: 1s;
}

.Home-Content p {
  font-size: 20px;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: 1s;
}
.home-sci {
  position: absolute;
  padding-top: 275px;
  left: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.home-sci a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid #a3bbad;
  border-radius: 50%;
  font-size: 20px;
  color: #a3bbad;
  text-decoration: none;
  margin: 30px 15px 30px 0;
  transition: 0.5s ease;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
  animation-delay: calc(0.2s * var(--i));
}
.home-sci a:hover {
  width: 50px;
  height: 50px;
  background: #fcc8b2;
  color: #1e1e24;
  box-shadow: 0 0 20px #fcc8b2;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slideTop {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media only screen and (max-width: 768px) {
    .home-sci a{
      margin-top: 20%;
    }
  }
@media only screen and (max-width: 350px) {
    .home-sci a{
      margin-top: 40%;
    }
  }
  
